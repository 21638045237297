<template>
  <div>
    <div v-if="show" class="invoice-box" id="invoice" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">
      <div class="row mb-5">
        <div class="col-9">
            <h2>{{ $t('sales_printing.receive_list') }}</h2>
        </div>

        <div class="col-3">
          <p><b>{{ company ? company.business_name : '' }}</b></p>
          <p>{{ company ? company.address_1 : '' }}</p>
          <p>{{ company ? company.email : '' }}</p>
        </div>

        <div class="col-12">
          <hr>
        </div>

        <div class="col-12">
          <p><b>{{ $t('sales_printing.invoice') }} # : </b> <span>{{data.invoice_code}}</span></p>
        </div>


      </div>
      <div class="row mb-5">
        <div class="col-12">
          <p><b>{{ $t('sales_printing.inventory') }}</b></p>
          <p>{{ data ? data.inventory_name : '' }}</p>
          <p>{{ (data && data.inventory) ? data.inventory.address_1 : '' }}</p>
        </div>

      </div>

      <div class="row mt-5">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>{{$t('sales_printing.description')}}</th>
              <th>{{$t('sales_printing.quantity')}}</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in items_list" :key="index">
                <td>{{row.description}}</td>
                <td>{{row.qty}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>{{$t('sales_printing.total')}}</th>
                <td>{{total_qty}}</td>
              </tr>

            </tfoot>

          </table>
          <br>
          <div :style="{'text-align': (lang == 'ar'? 'left': 'right')}">
            <vue-barcode :value="data.invoice_code" :options="{ displayValue: true }"></vue-barcode>
          </div>
        </div>

      </div>

<!--      <div>-->
<!--        <br>-->
<!--        <div :style="{'text-align': (lang == 'ar'? 'left': 'right')}">-->
<!--          <vue-barcode v-if="data.invoice_code" :value="data.invoice_code" tag="svg" :options="{ displayValue: true }"></vue-barcode>-->
<!--        </div>-->

<!--      </div>-->

    </div>

  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service";
import VueBarcode from '@chenfengyuan/vue-barcode';
import {jsPDF} from "jspdf";
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "packageLabelPrint",
  components: { VueBarcode },
  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: 'sales/general_sales',
      data: {},
      company: null,
      show: false,
      id: this.$route.params.id ? this.$route.params.id : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      items_list: [],
      total_qty: '0',
    }
  },
  methods: {
    async getData() {
      await ApiService.get(`${this.mainRoute}/${this.id}`).then((response) => {
        this.data = response.data.data;
        this.items_list = response.data.data.items_list;

        this.total_qty = this.$_.sumBy(response.data.data.items_list, (row) => {
          return row.qty ? parseInt(row.qty) : 0;
        });

        this.show = true;
      });
    },
    async getCompany() {
      await ApiService.get("/settings/company/getCompany").then((response) => {
        this.company = response.data.data ??  null;

      });
    },
    printInvoice() {
      // let tableId = 'invoice'
      // let divToPrint = document.getElementById(tableId);
      // window.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divToPrint.outerHTML);
      // i18nService.getActiveLanguage();
      // setTimeout(() => {
      //   window.print();
      //   window.close();
      // }, 100)
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");

      let pdf = jsPdfExport("p", "px", 'a4');
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
  },
  mounted() {
    let promise = this.getData();
    let promise2 = this.getCompany();
    Promise.all([promise, promise2]).then(() => {
      this.printInvoice();
    });
  }
}
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
</style>